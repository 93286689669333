<template>
  <div class="viewer-component">
    <a-avatar :size="40" :src="avatar">
      <a-icon slot="icon" type="user"/>
    </a-avatar>

    <div class="name">
      <span> {{ name }} </span>

      <!-- <Icon v-if="medal" :size="16" type="medal"/> -->
    </div>

    <a-button @click="select" :class="buttonClasses">
      <Icon :size="20" :type="invited ? 'checkbox-circle' : 'add-circle'"/>

      {{ $t(invited ? 'pages.newcompetition.invites.viewer.invited' : 'pages.newcompetition.invites.viewer.invite') }}
    </a-button>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
import { mapState } from 'vuex'
import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'Viewer',
  props: {
    viewer: {
      type: Object,
      required: true
    }
  },
  mixins: [
    newcompetition
  ],
  components: {
    Icon
  },
  computed: {
    ...mapState('newcompetition', ['viewers']),
    id () {
      return this.viewer.id
    },
    buttonClasses () {
      return {
        secondary: this.invited
      }
    },
    name () {
      return this.viewer.name
    },
    avatar () {
      return this.viewer.photo
    },
    medal () {
      return true
    },
    invited () {
      return this.viewers.find(({ id }) => id === this.id)
    }
  },
  methods: {
    select () {
      this.invited ? this.removeViewer(this.viewer) : this.addViewer(this.viewer)
    }
  }
}
</script>
