<template>
  <subview class="newcompetition-invites">
    <custom-header class="view-header" :router-back="$goBack">
      <template v-slot:default>
        <div>
          <Icon :size="24" type="arrow-left"/>

          {{ $t('pages.newcompetition.invites.title') }}
        </div>
      </template>

      <template #last>
        <div class="current-step"> {{ $t('pages.newcompetition.step') }} 5/6 </div>
      </template>
    </custom-header>

    <view-content>
      <a-input-search v-model="search" :placeholder="$t('search.userby')" size="large">
        <a-button slot="enterButton">
          <Icon :size="24" type="search"/>
        </a-button>
      </a-input-search>

      <Viewer v-for="user in viewers" :key="user.id" :viewer="user"/>
    </view-content>

    <view-footer>
      <a-button @click="next">
        {{ $t('pages.newcompetition.almost') }}

        <Icon :size="20" type="arrow-right" />
      </a-button>
    </view-footer>
  </subview>
</template>

<script>
import Icon from '@/components/Icon'
import Viewer from '@/components/NewCompetition/Viewer'
import newcompetition from '@/mixins/newcompetition'
import { mapState } from 'vuex'

export default {
  name: 'Invites',
  mixins: [
    newcompetition
  ],
  components: {
    Icon,
    Viewer
  },
  computed: {
    ...mapState({
      id: ({ auth }) => auth.user.id,
      users: ({ competition }) => competition.users
    }),
    viewers () {
      const { search } = this

      return this.users?.filter(({ id, name, nickname }) => {
        return id !== this.id && (
          !search
          || name.toLowerCase().includes(search.toLowerCase())
          || nickname?.toLowerCase().includes(search.toLowerCase())
        )
      }) || []
    }
  },
  data () {
    return {
      search: null
    }
  },
  methods: {
    next () {
      this.$router.push({ name: 'newcompetition.preview' })
    }
  }
}
</script>
